import React from "react";
import { Grid, Typography, Container } from "@mui/material";
import Seo from "../components/seo";
import Layout from "../styles/layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { colors } from "../styles/globalStyles";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { MDXRenderer } from "gatsby-plugin-mdx";
import previewPdf from "../../content/books/interviewbook/preview.pdf";

export default function Books({ props, location }) {
  const data = useStaticQuery(graphql`
    query {
      interviewBookCover: file(
        relativePath: { eq: "img/books/interview/cover.jpg" }
      ) {
        childImageSharp {
          cover: gatsbyImageData(width: 400)
        }
      }
      interviewBookIntro: file(
        relativePath: { eq: "interviewbook/intro.mdx" }
      ) {
        childMdx {
          body
        }
      }
      interviewBookContent: file(
        relativePath: { eq: "interviewbook/content.mdx" }
      ) {
        childMdx {
          body
        }
      }
    }
  `);

  let title = "Preparing for a technical iOS job interview";
  let subtitle = "with over 200 questions & answers";
  let purchaseLink = "https://tanaschita.gumroad.com/l/ebook-ios-qa";
  let gumroadButtonTitle = "Buy ebook on Gumroad";
  let image = data.interviewBookCover.childImageSharp.cover;

  return (
    <Layout location={location}>
      <Seo
        title="iOS development books on tanaschita.com"
        description="Preparing for a technical iOS Job Interview with over 200 questions & answers. Covering Swift & Objective-C, SwiftUI & UIKit, Combine, HTTP Networking, iOS File System, Core Data, Concurrency with async/await, Security, Automated Testing, Machine Learning and more."
      />
      <Container>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="h2">{subtitle}</Typography>
        <Box height="30px"></Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4} key="bookCover">
            <GatsbyImage
              image={image}
              alt="Book logo"
              border="1px solid #876349"
            />
            <Box height="6px"></Box>
            <a
              href={previewPdf}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <PreviewBookButtonContainer>
                Download preview
              </PreviewBookButtonContainer>
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={8} key="bookContent">
            <Typography variant="h3">About this book</Typography>
            <Typography fontSize="17px">
              <MDXRenderer>{data.interviewBookIntro.childMdx.body}</MDXRenderer>
            </Typography>
            <Box height="15px"></Box>
            <a
              href={purchaseLink}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <BuyBookButtonContainer>
                {gumroadButtonTitle}
              </BuyBookButtonContainer>
            </a>
          </Grid>
        </Grid>
        <Box height="20px"></Box>
        <Typography variant="h3">Content</Typography>
        <Typography>
          <MDXRenderer>{data.interviewBookContent.childMdx.body}</MDXRenderer>
        </Typography>
        <Box height="30px"></Box>
        <a
          href={purchaseLink}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <BuyBookButtonContainer>{gumroadButtonTitle}</BuyBookButtonContainer>
        </a>
      </Container>
    </Layout>
  );
}

const BuyBookButtonContainer = styled("div")(({ theme }) => ({
  fontSize: "22px",
  color: "#ffffff",
  fontWeight: "600",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "8px",
  paddingTop: "8px",
  backgroundColor: colors.primaryColor,
  borderRadius: "15px",
  display: "inline-block",
}));

const PreviewBookButtonContainer = styled("div")(({ theme }) => ({
  fontSize: "16px",
  color: colors.primaryColor,
  fontWeight: "600",
  paddingRight: "15px",
  paddingBottom: "5px",
  display: "inline-block",
}));
